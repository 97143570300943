import { createTheme, useSvelteUITheme } from "@svelteuidev/core";

export const theme = {
	...useSvelteUITheme(),
	...createTheme({
		fonts: {
			standard: "ff-meta-web-pro",
		},
		radii: {
			xs: "0",
			sm: "0",
			md: "0",
			lg: "0",
			xl: "0",
		},
	})
};

export enum FormState {
	Loading = "loading",
	Ready = "ready",
	Success = "success",
	Error = "error",
}

export function measureElement(element: HTMLElement) {
	const rect = element.getBoundingClientRect();
	const computedStyle = getComputedStyle(element);
	const marginTop = parseInt(computedStyle.marginTop, 10);
	const marginLeft = parseInt(computedStyle.marginLeft, 10);
	return {
		top: rect.top - marginTop,
		left: rect.left - marginLeft,
		width: rect.width,
		height: rect.height,
		marginTop: marginTop,
		marginLeft: marginLeft,
		paddingTop: parseInt(computedStyle.paddingTop, 10),
		paddingLeft: parseInt(computedStyle.paddingLeft, 10),
	};
}
